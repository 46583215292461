import React, { useEffect, useRef, useState } from 'react'
import {
  usePublicMenusForLocation,
  MenuSection,
  useOrder,
  CheckoutButton,
  ScrollSpy,
  // StructuredModal,
} from '@Pacific-Web-Services/component-library'
import 'react-loading-skeleton/dist/skeleton.css'
import { useWayfarerAccount } from '../hooks/useWayfarerAccount'
import PickupTimeView from '../PickupTime/PickupTimeView'
import { ProductPublicDto } from 'product/dto/product-public.dto'
import ProductSelector from '../Product/ProductSelector'
import { useLocation, useNavigate } from 'react-router-dom'
import InvalidOrderModal from '../Modals/InvalidOrderModal'
import styles from './Menus.module.css'
import MenuHeader from './MenuHeader'
import Banner from './Banner'
// import useIsHoliday from 'hooks/useIsHoliday'

export default function MenusRoute() {
  const { data: account, isLoading: isAccountLoading } = useWayfarerAccount()
  const locationId = account?.locations[0].id!
  const { order, setLocation } = useOrder()
  // const isHoliday = useIsHoliday()
  // const [pieNoticeShown, setPieNoticeShown] = useState(false)
  // const [pieNoticeOpen, setPieNoticeOpen] = useState(false)
  // useEffect(() => {
  //   if (isHoliday && !pieNoticeShown) {
  //     setPieNoticeOpen(true)
  //     setPieNoticeShown(true)
  //   }
  // }, [isHoliday, pieNoticeShown, setPieNoticeOpen])
  const navigate = useNavigate()
  useEffect(() => {
    if (account?.locations[0]) {
      setLocation(account?.locations[0])
    }
  }, [account, setLocation])
  const { data, isLoading } = usePublicMenusForLocation(locationId, {
    enabled: !!locationId,
  })
  const { pathname } = useLocation()
  let menus = data?.filter((menu) => menu.id === 2 || menu.id === 3)
  if (pathname === '/tacos') {
    menus = data?.filter((menu) => menu.id === 9 || menu.id === 15)
  }
  if (pathname === '/breakfastsandwiches') {
    menus = data?.filter((menu) => menu.id === 18)
  }
  if (pathname === '/christmas2024') {
    menus = data?.filter((menu) => menu.id === 20)
  }
  const [selectedMenu, setSelectedMenu] = useState(0)
  const [editTime, setEditTime] = useState(false)
  const promptedBy = useRef<ProductPublicDto>()
  const promptTimeSelect = (product: ProductPublicDto) => {
    promptedBy.current = product
    setEditTime(true)
  }
  const bottomRightContent = (product: ProductPublicDto) => (
    <ProductSelector
      product={product}
      promptTimeSelect={() => promptTimeSelect(product)}
    />
  )
  const onGoToCheckout = () => {
    window.scrollTo(0, 0)
    navigate('/checkout')
  }
  const sections = menus?.reduce(
    (sections: JSX.Element[], menu, mIndex) =>
      sections.concat(
        menu.sections.map((section, sIndex) => (
          <div key={section.name}>
            {sIndex === 0 && <MenuHeader menu={menu} id={`menu-${mIndex}`} />}
            <div className={styles.sectionContainer}>
              <MenuSection
                section={section}
                bottomRightContent={bottomRightContent}
              />
            </div>
          </div>
        )),
      ),
    [],
  )
  return (
    <div>
      <PickupTimeView
        isLoading={isAccountLoading || isLoading}
        isOpen={editTime}
        setIsOpen={setEditTime}
        onClose={() => (promptedBy.current = undefined)}
        promptedBy={promptedBy.current}
        clearPromptedBy={() => (promptedBy.current = undefined)}
      />
      <ScrollSpy
        titles={menus?.map((m) => m.name)}
        selectedIndex={selectedMenu}
        onSelect={setSelectedMenu}
        idExtractor={(i) => `menu-${i}`}
      >
        {(isAccountLoading || isLoading) && (
          <div>
            <Banner isLoading />
            <MenuSection />
          </div>
        )}
        {sections}
        {!!order.items.length && (
          <CheckoutButton order={order} onClick={onGoToCheckout} />
        )}
      </ScrollSpy>
      <InvalidOrderModal />
      {/* <StructuredModal
        isOpen={pieNoticeOpen}
        onRequestClose={() => setPieNoticeOpen(false)}
        title="A note to our loyal customers:"
      >
        Holiday pre-orders are only available for pickup on Sunday, December 24
        from 9am to 2pm. Orders must be placed by 5pm on Tuesday, December 19.
        Thanks and happy holidays!
      </StructuredModal> */}
    </div>
  )
}
